import request from "@/utils/request";



// 会议打分
export function judgeMeeting(data) {
    return request({
      url: "/home/meeting/judge",
      method: "post",
      data
    });
  }


// 查询会议详情
export function getDetailMeeting(data) {
  return request({
    url: "/home/meeting/meetingDetail",
    method: "post",
    data
  });
}

