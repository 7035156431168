<template>
  <div class="home-container" v-loading="loading">
    <div class="header">
      <div class="left-header">
        <switch-theme class="header-item"></switch-theme>
      </div>
      <div class="right-header">
        <language-icon class="header-item language"></language-icon>
        <user-info
          class="header-item user-info"
          :user-id="userId"
          :user-name="userName"
          :avatar-url="userAvatar"
          @log-out="handleLogOut"
        ></user-info>
      </div>
    </div>
    <room-control
      ref="roomControlRef"
      :given-room-id="givenRoomId"
      :user-name="userName"
      @create-room="handleCreateRoom"
      @enter-room="handleEnterRoom"
      @update-user-name="handleUpdateUserName"
    ></room-control>

    <!-- 评分管理 -->
    <!-- handleType 0管理员 1评委 2游客 -->
    <div
      class="scoring-btn"
      @click="handleReview"
      v-if="projectApplicationInfo.handleType == 1"
    >
      评分
    </div>

    <!-- 评委审核 参数配置对话框 -->
    <el-dialog
      title="评审操作"
      :visible.sync="openReview"
      width="1400px"
      append-to-body
    >
      <el-form
        ref="formReview"
        :model="formReview"
        :rules="rulesReview"
        label-width="100px"
        class="formReview-wrapper"
      >
        <div class="formReview-title">会议基本信息</div>
        <div class="basic-info">
          <ul class="">
            <!-- class="wid10" -->
            <li class="wid10">
              <p>会议名称：</p>
              <p>{{ formReview.meetingName || "" }}</p>
            </li>
            <li class="wid10">
              <p>会议号：</p>
              <p>{{ formReview.meeting_number || "" }}</p>
            </li>
            <li>
              <p>开始时间：</p>
              <p>{{ formReview.start_time || "" }}</p>
            </li>
            <li>
              <p>结束时间：</p>
              <p>{{ formReview.end_time || "" }}</p>
            </li>
          </ul>
        </div>

        <el-row :gutter="20">
          <!-- 评分规则列表 -->

          <el-col :span="24">
            <div class="formReview-title">评分规则列表</div>

            <el-table
              v-loading="loading"
              :data="formReview.scoring_rules"
              border
              size="mini"
              :header-cell-style="{ background: '#F8F8F8', color: '#666' }"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="规则名称"
                prop="description"
                show-overflow-tooltip
                width="600"
              />

              <el-table-column
                label="参考最高分值"
                align="center"
                prop="max_score"
                show-overflow-tooltip
                width="140"
              />

              <el-table-column
                label="评委分值"
                align="center"
                prop="score"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-form-item
                    label="分值:"
                    :prop="'scoring_rules.' + scope.$index + '.score'"
                    :rules="[
                      {
                        required: true,
                        message: '评委分值输入不能为空',
                        trigger: 'blur',
                      },
                      {
                        required: true,
                        validator: validator.regularNum.bind(
                          this,
                          '',
                          scope.row.max_score
                        ),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.score"
                      placeholder="请输入评委分值"
                      size="mini"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-col>

          <el-col :span="24" class="mt20">
            <el-form-item label="评审意见" prop="comments">
              <el-input
                v-model="formReview.comments"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 20 }"
                placeholder="请输入评审意见"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitReviewForm" :loading="loading"
          >确 定</el-button
        >
        <el-button @click="cancelReview">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 正则方法引入
import validator from "@/utils/validator.js";

import {
  // 会议打分
  judgeMeeting,
  // 查询会议详情
  getDetailMeeting,
} from "@/api/meeting.js";

import UserInfo from "@/TUIRoom/components/RoomHeader/UserInfo";
import LanguageIcon from "@/TUIRoom/components/common/Language.vue";
import SwitchTheme from "@/TUIRoom/components/common/SwitchTheme.vue";
import RoomControl from "@/TUIRoom/components/RoomHome/RoomControl";
import { getBasicInfo } from "@/config/basic-info-config";
import TUIRoomEngine from "@tencentcloud/tuiroom-engine-js";
import useGetRoomEngine from "@/TUIRoom/hooks/useRoomEngine";
import { isMobile } from "../TUIRoom/utils/environment";
import logger from "../TUIRoom/utils/common/logger";
import TUIMessageBox from "@/TUIRoom/components/common/base/MessageBox/index";
import useTRTCDetect from "@/TUIRoom/hooks/useTRTCDetect";
const roomEngine = useGetRoomEngine();

export default {
  name: "Home",
  components: {
    UserInfo,
    LanguageIcon,
    RoomControl,
    SwitchTheme,
  },
  setup() {
    const { isSupportTRTC } = useTRTCDetect();
    return {
      isSupportTRTC,
    };
  },
  data() {
    let _this = this;
    return {
      givenRoomId: "",
      basicInfo: null,
      userName: "",
      userAvatar: "",
      userId: "",
      isMobile,

      loading: false,
      /* 课题参数 */
      projectApplicationInfo: {
        // 会议id
        meeting_id: "",
        // 会议号
        meeting_number: "",
        // handleType 0管理员 1评委 2游客
        handleType: "",
        token: "",
        userId: "",
        // 用户姓名
        trueName: "",
      },

      /* 评审操作 */
      // 是否显示弹出层
      openReview: false,
      formReview: {
        // 会议id
        meetingId: "",

        // 会议名称
        meetingName: "",
        // 会议号
        meeting_number: "",
        // 会议开始时间
        start_time: "",
        // 会议结束时间
        end_time: "",

        // 评分规则列表
        scores: [],
        scoring_rules: [],
        // 评审意见
        comments: "",
        // 总分
        totalScore: 0,
      },
      // 表单校验
      rulesReview: {
        score: [
          {
            required: true,
            message: "评委分值输入不能为空",
            trigger: "blur",
          },
          {
            required: true,
            validator: validator.regularNum.bind(this, _this),
            trigger: "blur",
          },
        ],

        comments: [
          {
            required: true,
            message: "评审意见不能为空",
            trigger: "blur",
          },
        ],
      },

      validator,
    };
  },
  async mounted() {
    /* 会议评分 数据初始化 */
    this.init();

    sessionStorage.removeItem("tuiRoom-roomInfo");
    sessionStorage.removeItem("tuiRoom-userInfo");
    this.givenRoomId = this.$route.query.roomId || "";

    if (sessionStorage.getItem("tuiRoom-userInfo")) {
      this.basicInfo = JSON.parse(sessionStorage.getItem("tuiRoom-userInfo"));
    } else {
      this.basicInfo = await getBasicInfo();
      this.basicInfo &&
        sessionStorage.setItem(
          "tuiRoom-userInfo",
          JSON.stringify(this.basicInfo)
        );
    }
    // this.userName = this.basicInfo.userName;

    // this.userName = this.basicInfo.userName;

    this.userAvatar = this.basicInfo.userAvatar;
    this.userId = this.basicInfo.userId;
    const { sdkAppId, userId, userSig } = this.basicInfo;
    // 登录 TUIRoomEngine
    await TUIRoomEngine.login({ sdkAppId, userId, userSig });
  },
  methods: {
    /* 会议评分 数据初始化 */
    init() {
      let query = this.$route.query;
      console.log("query", query);
      if (JSON.stringify(query) === "{}") {
        query = JSON.parse(
          window.sessionStorage.getItem("projectApplicationInfo") || "{}"
        );
      } else {
        /* 课题参数 */
        this.projectApplicationInfo = {
          ...this.projectApplicationInfo,
          ...query,
        };
        window.sessionStorage.setItem(
          "projectApplicationInfo",
          JSON.stringify(this.projectApplicationInfo)
        );
      }

      console.log("projectApplicationInfo", this.projectApplicationInfo);
      // 用户姓名
      this.userName = this.projectApplicationInfo.trueName || "";

      console.log(
        "==============================================================================init|======================================================"
      );
    },

    setTUIRoomData(action, mode) {
      const roomParam = this.$refs.roomControlRef.getRoomParam();
      const roomData = {
        action,
        roomMode: mode || "FreeToSpeak",
        roomParam,
      };
      sessionStorage.setItem("tuiRoom-roomInfo", JSON.stringify(roomData));
    },
    async checkRoomExistWhenCreateRoom(roomId) {
      let isRoomExist = false;
      const tim = roomEngine.instance?.getTIM();
      try {
        await tim.searchGroupByID(roomId);
        isRoomExist = true;
      } catch (error) {
        // 房间不存在
      }
      return isRoomExist;
    },
    // 创建房间时生成房间号
    async generateRoomId() {
      const roomId = Math.ceil(Math.random() * 1000000);
      const isRoomExist = await this.checkRoomExistWhenCreateRoom(
        String(roomId)
      );
      if (isRoomExist) {
        return await this.generateRoomId();
      }
      return roomId;
    },
    // 处理点击【创建房间】
    async handleCreateRoom(mode) {
      if (!this.isSupportTRTC) {
        TUIMessageBox({
          title: this.$t("Note"),
          message: this.$t(
            "The current browser does not support audio and video communication capabilities"
          ),
          appendToRoomContainer: true,
          confirmButtonText: this.$t("Sure"),
        });
        return;
      }
      this.setTUIRoomData("createRoom", mode);
      const roomId = await this.generateRoomId();
      this.$router.push({ path: "room", query: { roomId } });
    },
    // 处理点击【进入房间】
    async handleEnterRoom(roomId) {
      if (!this.isSupportTRTC) {
        TUIMessageBox({
          title: this.$t("Note"),
          message: this.$t(
            "The current browser does not support audio and video communication capabilities"
          ),
          appendToRoomContainer: true,
          confirmButtonText: this.$t("Sure"),
        });
        return;
      }
      this.setTUIRoomData("enterRoom");
      this.$router.push({
        path: "room",
        query: {
          roomId,
        },
      });
    },
    // 处理用户点击页面左上角【退出登录】
    handleLogOut() {
      // 接入方处理 logout 方法
    },
    // 更新用户自己修改的userName
    handleUpdateUserName(userName) {
      try {
        const storageUserInfo = JSON.parse(
          sessionStorage.getItem("tuiRoom-userInfo")
        );
        storageUserInfo.userName = userName;
        sessionStorage.setItem(
          "tuiRoom-userInfo",
          JSON.stringify(storageUserInfo)
        );
      } catch (error) {
        logger.log("sessionStorage error", error);
      }
    },

    /** 提交评委审核 按钮操作 */
    async handleReview(row, type) {
      const _this = this;
      _this.loading = true;
      const res = await getDetailMeeting({
        meetingId: this.projectApplicationInfo.meeting_id,
      });

      _this.loading = false;

      if (res.code !== 200) return this.$message.error(res.msg);
      let meeting = res.meeting;
      let scoring_rules = meeting.scoring_rules || [];

      //浅克隆
      let params = {
        // 会议名称
        meetingName: meeting.name,
        // 会议id
        meetingId: meeting.id,
        // 会议号
        meeting_number: meeting.meeting_number,
        // 会议开始时间
        start_time: meeting.start_time,
        // 会议结束时间
        end_time: meeting.end_time,

        // 评分规则列表
        scoring_rules: scoring_rules.map((item) => ({
          // ...item,
          scoring_rule_id: item.id,
          description: item.description,
          max_score: item.max_score,
          score: "",
        })),
        // 评审意见
        comments: "",
        // 总分
        totalScore: 0,
      };
      this.formReview = params;
      this.openReview = true;
    },
    /** 提交评委审核 提交操作 */
    async submitReviewForm() {
      const _this = this;
      const valid = await this.$refs.formReview.validate();
      if (!valid) return;

      console.log("this.formReview", this.formReview);

      // 总分
      let totalScore = 0;
      this.formReview.scoring_rules.forEach((item) => {
        totalScore += item.score * 1;
      });

      let scoresList = this.formReview.scoring_rules.map((item) => ({
        scoring_rule_id: item.scoring_rule_id,
        score: item.score,
      }));

      let params = {
        // 会议id
        meetingId: this.formReview.meetingId,

        // scores: JSON.stringify(scoresList),
        scores: scoresList,


        // 评审意见
        comments: this.formReview.comments,
        // 总分
        totalScore,
      };

      console.log("params", params);
      // return false;
      _this.loading = true;
      const res = await judgeMeeting(params);
      _this.loading = false;

      if (res.code !== 200) return this.$message.error(res.msg);

      this.$message.success("评审成功");

      this.openReview = false;

      // this.getList();
    },

    // 取消按钮
    cancelReview() {
      this.openReview = false;
    },
  },
};
</script>

<style>
@import "../TUIRoom/assets/style/global.scss";
@import "../TUIRoom/assets/style/black-theme.scss";
@import "../TUIRoom/assets/style/white-theme.scss";
</style>

<style lang="scss" scoped>
.tui-theme-black .home-container {
  --background: var(--background-color-1);
}
.tui-theme-white .home-container {
  --background: url(../TUIRoom/assets/imgs/background-white.png);
}

.home-container {
  width: 100%;
  height: 100%;
  background: var(--background);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFang SC;
  color: var(--font-color-1);
  .header {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 22px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-header,
    .right-header {
      display: flex;
      align-items: center;
      .header-item {
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }
  }
}

/* 评分管理 */
.scoring-btn {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translate(-50%);
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  background: #1c66e5;
  /* border: 1px solid #ddd; */
  border-radius: 100%;
  z-index: 11;
  transition: 0.3s;
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  &:hover {
    /*图片中心放大代码*/
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
  }
}

/* 评委评分 */
.formReview-wrapper {
  margin: 0 30px 10px;
}
.form-border {
  border: 1px solid #dddcdc;
  border-radius: 6px;
  overflow: hidden;
  margin: 10px auto 20px;
  padding: 23px 0 0;
}

.mt20 {
  margin: 20px auto;
}

.formReview-title {
  color: #1890ff;
  font-size: 16px;
  position: relative;
  margin: 0 auto 20px;
  text-indent: 15px;

  &::before {
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: 4px;
    height: 100%;
    background: #1890ff;
  }
}
/* 课题申报 */
.basic-info {
  .basic-title {
    font-size: 22px;
    font-weight: 700;
    color: #1c1c1c; /* text-align:center;*/
    padding: 10px 0;
    margin-bottom: 20px;
    overflow: hidden;
    i {
      font-style: normal;
      color: #0076ff;
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    margin: 0 auto 15px;
    border-radius: 4px;
    padding: 0;
    li {
      width: 50%;
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      &.wid10 {
        width: 100%;
      }
      &.wid11 {
        width: 100%;
        p {
          font-size: 24px;
          color: red;
        }
      }
      p {
        padding: 10px 15px;
        margin: 0;
        border-right: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        color: #333;
        font-size: 14px;
        text-align: left;
        &:first-child {
          width: 180px;
          background: #f5f5f5;
        }
        &:last-child {
          flex: 1;
        }
      }
    }
  }
}
</style>
