
/**
 *  根据不同的参考最高分值 正则验证
 *
 */
export default {
  
    regularNum(rows,max_score,  rule, value, callback) {
      console.log("rows", rows);
      console.log("max_score", max_score);
     
      console.log("rule", rule);
      console.log("value", value);
      console.log("callback", callback);
      let max_score_num = max_score;
     
      var valid = /^(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      
      if (!valid.test(value)) {
        callback(new Error("请输入数字或小数"));
      } else if (value*1 > max_score_num*1) {
        callback(new Error("输入不能大于参考最高分值:" + max_score_num));
      } else {
        callback();
      }
    }
  };
  