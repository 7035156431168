export default {
  '': '',
  'Sign in': '请登录',
  'Phone Login': '手机登录',
  'Email Login': '邮箱登录',
  'I have read and agree to the': '我已阅读并同意',
  'Privacy Policy': '隐私协议',
  and: '和',
  'Terms of Use': '用户协议',
  Login: '登录',
  'Mobile number': '请输入您的手机号',
  'Verification code': '请输入验证码',
  'Email address': '请输入邮箱',
  'Please enter a valid phone number!': '请输入正确的手机号！',
  'Please enter a valid email address!': '请输入正确的邮箱地址！',
  'Please enter your phone number!': '请输入手机号！',
  'Please enter your email address!': '请输入邮箱地址！',
  'Please enter the verification code!': '请输入验证码！',
  'Please accept the privacy policy and user agreement!': '请同意隐私协议及用户协议！',
  'Incorrect verification code, please check the code!': '验证码错误，请检查验证码！',
  'The verification code has expired, please retrieve a new one!': '验证码已过期，请重新获取验证码！',
  'The verification code has been used, please retrieve a new one!': '验证码已使用，请重新获取验证码！',
  'Login failed, please try again.': '登录失败，请重试',
  SEND: '获取验证码',
  ' ': '倒计时',
  'The room does not exist, please confirm the room number or create a room!': '房间不存在，请确认房间号或创建房间！',
  'Log out': '退出登录',
  'Edit profile': '编辑资料',
  'User Name': '用户名',
  'Please input user name': '请输入用户名',
  'Username length should be greater than 0': '昵称长度应该大于0',
  Save: '保存',
  Camera: '摄像头',
  Mic: '麦克风',
  'Off Camera': '摄像头已关闭',
  'Mic settings': '麦克风设置',
  Output: '输出',
  Speaker: '扬声器',
  Test: '测试',
  Stop: '停止测试',
  Preview: '视频画面',
  Mirror: '视频镜像',
  Resolution: '分辨率',
  'More Camera Settings': '更多摄像头设置',
  'Join the room ?': '是否进入房间?',
  'You are invited to room ': '您被邀请进入 ',
  Room: '房间',
  Join: '确定进入',
  'Switch Theme': '换肤',
  Layout: '布局',
  'New Room': '新建房间',
  'Room Type': '房间类型',
  'Your Name': '您的名字',
  'Turn on the microphone': '开启麦克风',
  'Turn on the speaker': '开启扬声器',
  'Turn on the video': '开启视频',
  'Free Speech Room': '自由发言房间',
  'On-stage Speaking Room': '上台发言房间',
  'Join Room': '进入房间',
  'Enter room ID': '输入房间号',
  'video conferencing': ' 的视频会议',
  'Quick Meeting': '的快速会议',
  'Room ID': '房间号',
  'Low Definition': '流畅',
  'Standard Definition': '标清',
  'High Definition': '高清',
  'Super Definition': '超清',
  'End sharing': '结束共享',
  'Stop sharing?': '是否停止屏幕共享？',
  'Stop sharing': '停止共享',
  'You are sharing the screen...': '你正在共享屏幕...',
  Sure: '确定',
  Sharing: '屏幕共享中',
  'Share screen': '共享屏幕',
  'Grant permission to screen recording': '打开屏幕录制权限',
  'Open the system preferences settings': '打开系统便好设置',
  'Due to macOS 10.15 system requirements, please check the current application in "System Preferences - Security & Privacy - Screen Recording".': '由于macos 10.15 系统要求，请在"系统偏好设置-安全性与隐私-屏幕录制"中勾选当前应用程序。',
  'Others will no longer see your screen after you stop sharing. Are you sure you want to stop?': '是否结束当前的共享屏幕，停止后所有人将无法继续观看屏幕内容',
  'Others will no longer see your screen after you stop sharing.': '停止后所有人将无法继续观看屏幕内容',
  'You currently do not have sharing permission, please raise your hand to apply for sharing permission first': '您当前没有共享权限，请先举手申请上台获取共享权限',
  Host: '房主',
  Me: '我',
  me: '我',
  Admin: '管理员',
  RoomOwner: '主持人',
  More: '更多',
  'Raise hand': '举手',
  Agree: '同意',
  Chat: '聊天',
  Cancel: '取消',
  Exit: '退出全屏',
  'Full screen': '全屏',
  Members: '成员',
  'Search Member': '搜索成员',
  'Transfer owner': '转交房主',
  'Set as administrator': '设为管理员',
  'Revoke administrator': '撤销管理员',
  // @ts-ignore
  'The administrator status of sb has been withdrawn': ({ named }) => `已将 ${named('name')} 的管理员身份撤回`,
  // @ts-ignore
  'sb has been set as administrator': ({ named }) => `已将 ${named('name')} 设为管理员`,
  'You have been invited by the administrator to step down, please raise your hand if you need to speak': '您已被管理员邀请下台，需要发言请先举手',
  Invite: '邀请',
  Settings: '设置',
  EndPC: '结束房间',
  EndH5: '结束',
  Tips: '提示',
  'You are currently the host of the room, please choose the corresponding operation. If you choose "End Room", the current room will be disbanded and all members will be removed. If you choose "Leave Room", the current room will not be disbanded, and your hosting privileges will be transferred to other members.': '您当前是房间主持人，请选择相应操作。若选择“结束房间”，将解散当前房间并将全体成员移出。若选择“离开房间”，当前房间不会解散，您的主持人权限将移交给其他成员。',
  'You are currently the host of the room, please choose the corresponding operation. If you choose "End Room", the current room will be disbanded and all members will be removed.': '您当前是房间主持人，请选择相应操作。若选择“结束房间”，将解散当前房间并将全体成员移出。',
  'If you do not want to end the meeting, please designate a new host before leaving the meeting.': '如果您不想结束会议，请在离开会议前指定新的主持人。',
  'Are you sure you want to leave this room?': '确定离开房间吗？',
  'New host': '选择主持人',
  'Transfer and leave': '移交并离开',
  'Leave room?': '是否要离开房间',
  'Select a new host': '请选择新的房间主持人',
  'Appoint a new host': '指定一位新的主持人',
  'The host closed the room.': '主持人结束会议，已解散房间',
  Note: '通知',
  Confirm: '确认',
  Leave: '离开房间',
  Dismiss: '结束房间',
  // @ts-ignore
  'The administrator has been changed to ': '管理员已变更为',
  'Muted by the moderator': '已被主持人禁言',
  'Type a message': '说点什么...',
  Send: '发送',
  'Failed to send the message': '发送消息失败',
  'Applying for the stage': '正在申请上台',
  'Apply for the stage': '申请上台',
  Check: '查看',
  'Disable all audios': '全体静音',
  'Enable all audios': '解除全体静音',
  'Disable all videos': '全体静画',
  'Enable all videos': '解除全体静画',
  'All mute': '全体静音',
  'All current and new members will be muted': '所有及新加入的成员将被静音',
  'All and new members will be banned from the camera': '所有及新加入的成员将被禁止视频',
  'All stop video': '全体禁画',
  'Lift all mute': '取消全体静音',
  'Lift stop all video': '取消全体禁画',
  'No relevant user found.': '未找到相关用户',
  'Member List': '成员列表',
  Unmute: '解除静音',
  'Cancel Unmute': '取消解除禁言',
  Mute: '静音',
  members: '人',
  'Failed to get chat message': '获取聊天消息失败',
  'Agree to the stage': '同意上台',
  'Cancel stage': '取消邀请上台',
  'Invite stage': '邀请上台',
  'Refuse stage': '拒绝上台',
  'Members applying on stage': '成员上台申请',
  'Enable video': '请求开启视频',
  'Cancel Enable video': '取消解除禁画',
  'Disable video': '关闭视频',
  'Enable chat': '解除禁言',
  'Disable chat': '禁言',
  'Kick out': '踢出房间',
  'is sharing their screen': '的屏幕分享',
  'Apply to stage': '上台申请',
  'Apply to stage application': '请求上台申请',
  Reject: '拒绝',
  'Reject All': '全部拒绝',
  'Agree All': '全部同意',
  'Please raise your hand to apply': '如果您想发言请先举手申请上麦',
  'You can turn on the microphone and camera once you are on stage': '上台后可以打开麦克风和摄像头',
  // @ts-ignore
  'Sb invites you to speak on stage': ({ named }) => `${named('role')}邀请您上台发言`,
  'After agreeing to go on stage, you can turn on the camera and microphone. Do you agree to go on stage?': '同意上台后可打开摄像头和麦克风，是否同意上台？',
  'Hand down': '手放下',
  'Step down(Master)': '邀请下台',
  'Step down': '下台',
  // @ts-ignore
  'sb has rejected your application for the stage': ({ named }) => `${named('role')}拒绝了你的上台申请`,
  'You have been invited by the host to step down, please raise your hand if you need to speak': '您已被主持人邀请下台，需要发言请先举手',
  'Select a screen/window': '选择屏幕/窗口',
  Screen: '屏幕',
  Window: '窗口',
  Share: '开始分享',
  'Safety Reminder': '安全提醒',
  'Continue sharing': '继续共享',
  'Select a screen or window first': '请选择要分享的屏幕或者窗口',
  Grid: '一屏九等分',
  'Gallery on right': '右侧成员列表',
  'Gallery at top': '顶部成员列表',
  'Network ': '网络',
  'Latency: ': '网络延迟：',
  'Frame rate: ': '帧率：',
  'Bitrate: ': '码率：',
  Unknown: '状态未知',
  Excellent: '状态极佳',
  Good: '状态较好',
  Fair: '状态一般',
  Poor: '状态差',
  'Very poor': '状态极差',
  Disconnected: '断开连接',
  'Share the room ID or invite link': '您可以通过复制房间号或者邀请链接的方式邀请更多人加入房间',
  'Invite by room number': '通过房间号邀请',
  'Invite via room link': '通过房间链接邀请',
  'Room Link': '房间链接',
  'You can share the room number or link to invite more people to join the room.': '您可以分享房间号或链接邀请更多人加入房间。',
  'You can share the room number to invite more people to join the room': '您可以分享房间号邀请更多人加入房间',
  'If you have any questions, please feel free to join our QQ group or send an email': '如果有任何问题欢迎加入我们的QQ群或者发送邮件。',
  'If you have any questions, please email us.': '如果有任何问题欢迎加入我们的交流群或者发送邮件。',
  'Join our product discussion group': '加入产品交流群',
  'Our email address': '我们的邮件地址',
  'Join now': '立即加入',
  'Room QR code': '房间二维码',
  'Search for conference attendees': '搜索参会成员',
  'Invite via client scheme': '通过客户端 scheme 邀请',
  'Contact us': '联系我们',
  'Member management': '成员管理',
  'Grid view': '宫格视图',
  'Join our QQ group chat or email us.': '如果有任何问题欢迎加入我们的QQ群或者发送邮件',
  'group chat': 'QQ 群',
  Email: '邮箱地址',
  'Audio settings': '声音设置',
  'Camera settings': '摄像头设置',
  Copy: '复制',
  'Copied successfully': '复制成功',
  'accepted the invitation to the stage': '接受了上台邀请',
  'declined the invitation to the stage': '拒绝了上台邀请',
  'All audios disabled': '已开启全体静音',
  'All audios enabled': '已解除全体静音',
  'Your microphone has been turned off': '已关闭您的麦克风',
  // @ts-ignore
  'Sb invites you to turn on the microphone': ({ named }) => `${named('role')}邀请你打开麦克风`,
  'All videos disabled': '已开启全体禁画',
  'All videos enabled': '已解除全体禁画',
  'Disabling text chat for all is enabled': '已开启全体禁止文字聊天',
  'Unblocked all text chat': '已解除全体禁止文字聊天',
  'Your camera has been turned off': '已关闭您的摄像头',
  // @ts-ignore
  'Sb invites you to turn on the camera': ({ named }) => `${named('role')}邀请你打开摄像头`,
  'The host has turned off your screen sharing': '主持人已关闭您的屏幕分享',
  'Turn on the camera': '打开摄像头',
  'Keep it closed': '保持关闭',
  'You have been banned from text chat': '您被禁止文字聊天',
  'You are allowed to text chat': '您被允许文字聊天',
  'kicked out of the room by the host': '被主持人踢出房间',
  'kicked out of the room by other device': '相同账号在其他客户端进入房间',
  'kicked out of the room by serve': '被服务端踢出房间',
  'Reject on Stage failed, please retry': '拒绝 {userName} 上台失败, 请重试',
  'Has been fully muted and cannot open the microphone': '已被全员静音，无法打开麦克风',
  'Has been muted by the host and cannot open the microphone': '已被主持人静音，无法打开麦克风',
  'To apply to speak in the room, please raise your hand first to apply for the microphone': '申请发言房间，请先举手申请上麦',
  'Has been full static painting, can not open the video': '已被全员静画，无法打开视频',
  'It has been stilled by the host and cannot open the video': '已被主持人静画，无法打开视频',
  'Failed to enter the room.': '进入房间失败！',
  'Has been full static painting, can not share your screen': '已被全员静画，无法打开屏幕分享',
  'Microphone not detected on current device': '当前设备未检测到麦克风',
  'Camera not detected on current device': '当前设备未检测到摄像头',
  'Camera And Microphone not detected on current device': '当前设备未检测到摄像头和麦克风',
  'Another user is currently sharing the screen, screen sharing is not possible.': '当前有其他用户正在屏幕共享，无法进行屏幕共享',
  'This action causes the room to be exited, does it continue?': '该操作会导致退出房间，是否继续？',
  'This action causes the room to be disbanded, does it continue?': '该操作会导致解散房间，是否继续？',
  'Sharing screens may lead to the leakage of private information such as SMS verification codes and passwords, resulting in financial losses. Please be vigilant against various forms of fraud.': '共享屏幕时可能会泄露短信验证码、密码等隐私信息，造成财产损失，请警惕各种诈骗行为',
  'Please enter the room number': '请输入房间号',
  'The current browser does not support capturing audio and video': '当前浏览器不支持采集音频和视频',
  'The current browser does not support getting microphone and camera list': '当前浏览器不支持获取麦克风和摄像头列表',
  'The current browser does not support screen sharing': '当前浏览器不支持屏幕分享',
  'The current browser does not support capturing audio': '当前浏览器不支持采集音频',
  'The current browser does not support capturing video': '当前浏览器不支持采集视频',
  'The current browser does not support audio and video communication capabilities': '当前浏览器不支持音视频通信能力',
  // @ts-ignore
  'whether to kick sb off the room': ({ named }) => `是否将 ${named('name')} 移出房间`,
  // @ts-ignore
  'An invitation to open the microphone has been sent to sb.': ({ named }) => `已向 ${named('name')} 发出开启麦克风邀请`,
  // @ts-ignore
  'An invitation to open the camera has been sent to sb.': ({ named }) => `已向 ${named('name')} 发出开启摄像头邀请`,
  'Transfer owner failed, please try again.': '转交房主失败，请重试',
  // @ts-ignore
  'Sb has been set as an administrator': ({ named }) => `已将 ${named('name')} 设为管理员`,
  'Succeed on stage': '上台成功',
  'After unlocking, users can freely turn on the microphone': '解除后用户可以自由开启麦克风',
  'Members will not be able to open the microphone': '成员将无法开启麦克风',
  'After unlocking, users can freely turn on the camera': '解除后用户可以自由开启视频',
  'Members will not be able to open the camera': '成员将无法开启视频画面',
  'You have become a administrator': '您已成为管理员',
  'You are now a room owner': '您已成为房主',
  'Your administrator status has been revoked': '您的管理员身份被收回',
  'After transfer the room owner, you will become a general user': '转交房主后将成为普通成员',
  // @ts-ignore
  'Transfer the roomOwner to sb': ({ named }) => `将房主转移给${named('name')}`,
  'Confirm transfer': '确认转交',
  // @ts-ignore
  'The room owner has been transferred to sb': ({ named }) => `已将房主转交给${named('name')}`,
  'Application to go on stage was rejected': '上台申请被拒绝',
  'You are now an administrator': '您已成为管理员',
  'The RoomOwner has withdrawn your administrator privileges': '房主已收回您的管理员权限',
  'This member has already received the same request, please try again later': '该成员已收到相同请求，请稍后再试',
  'Failed to go on stage, invitation has timed out': '上台失败，邀请已超时',
  'The current number of people on stage has reached the limit': '当前上台人数已达上限',
  // @ts-ignore
  'The invitation to sb to go on stage has timed out': ({ named }) => `对${named('name')}的上台邀请已超时`,
};
